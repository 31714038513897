import React, { useState, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Typography,
  makeStyles,
  Alert,
} from '@mui/material';
import styled from 'styled-components';
import { Visibility } from '@mui/icons-material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { EnviarCorreopdf, TareasProveedor, ingresoPDFWorkmanagerFlujo, TareasProveedorObseracion, ingresoDOCXWorkmanagerFlujo } from '../FunctionLogin'
import { generatePDF } from '../FuncionPDF'
import SignatureCanvas from "react-signature-canvas";
import swal from 'sweetalert';



const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EvaluationTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const TableContainerStyled = styled(TableContainer)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const TableCellHead = styled(TableCell)`
  font-weight: bold;
  background-color: #f5f5f5;
`;

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  padding: 12px;
`;

const SelectFormControl = styled(FormControl)`
  min-width: 80px;
`;

export default function EvaluationVisita() {
  const optionsContacto = [
    'VENTAS',
    'CALIDAD',
    'COMERCIO INTERNACIONAL',
    "OTRO"
  ];

  const sigPad = useRef({});
  const sigPad2 = useRef({});
  const initialRow = [
    {
      contacto: '',
      nombre: '',
      telefono: '',
      email: '',
    }
  ];


  const [loading, setLoading] = useState(false);
  const [firmaFile, setFirmaFile] = useState(null);
  const [firmaFile2, setFirmaFile2] = useState(null);
  const [rows, setRows] = useState([{
    contacto: 'VENTAS',
    nombre: '',
    telefono: '',
    email: '',
  },
  {
    contacto: 'CALIDAD',
    nombre: '',
    telefono: '',
    email: '',
  },
  {
    contacto: 'COMERCIO INTERNACIONAL',
    nombre: '',
    telefono: '',
    email: '',
  }
    ,
  {
    contacto: 'OTRO',
    nombre: '',
    telefono: '',
    email: '',
  }]);
  const handleAddRow = () => {
    setRows([...rows, initialRow]);
  };


  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const options = [
    'SERVICIOS DE TRANSPORTE NACIONAL',
    'SERVICIOS INFORMÁTICOS',
    'SERVICIOS MECANIZADOS O METALMECÁNICOS',
    'SERVICIOS DE AGENCIA DE ADUANAS',
    'SERVICIOS DE TRANSPORTE INTERNACIONAL',
    'SERVICIOS DE PAQUETEO',
    'SERVICIOS DE INGENIERÍA',
    'SERVICIOS LEGALES',
    'SERVICIOS CONTABLES E IMPUESTOS',
    'SERVICIOS LOGÍSTICOS',
    'SUMINISTROS MATERIAS PRIMAS',
    'SUMINISTROS MATERIAL DE EMPAQUE',
    'SUMINISTROS REPUESTOS',
    'SUMINISTROS MAQUINARIA Y EQUIPOS PARA LA VENTA',
    'SUMINISTROS DE CONSUMIBLES Y ACCESORIOS PARA LA VENTA',
    'ALMACENAMIENTO',
    "ENSAYOS DE LABORATORIO",
    "SELLOS DE SEGURIDAD"
  ];


  const [fechaDiligenciamiento, setFechaDiligenciamiento] = useState('');

  const [responsableVisita, setResponsableVisita] = useState('');
  const [responsableRecibir, setResponsableRecibir] = useState('');

  const [pais, setPais] = useState(localStorage.Pais);
  const [razonSocial, setRazonSocial] = useState(localStorage.Tproveedor);
  const [direccion, setDireccion] = useState(localStorage.Direcc);
  const [vinculosCiTalsa, setVinculosCiTalsa] = useState('');
  const [ciudad, setCiudad] = useState(localStorage.Ciudad);
  const [telefono, setTelefono] = useState(localStorage.Telefono);
  const [promedio, setPromedio] = useState('');
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [opcionSeleccionadaContacto, setOpcionSeleccionadaContacto] = useState('');

  const handleFechaDiligenciamientoChange = (event) => {
    setFechaDiligenciamiento(event.target.value);
  };

  const handlePaisChange = (event) => {
    setPais(event.target.value);
  };

  const handleRazonSocialChange = (event) => {
    setRazonSocial(event.target.value);
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };

  const handleVinculosCiTalsaChange = (event) => {
    setVinculosCiTalsa(event.target.value);
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };
  const handleTelefonoChangeChange = (event) => {
    setTelefono(event.target.value);
  };
  const handlePromedioChangeChange = (event) => {
    setPromedio(event.target.value);
  };

  const handleOpcionSeleccionadaContactoChange = (event) => {
    setOpcionSeleccionadaContacto(event.target.value);
  };
  const handleOpcionSeleccionadaChange = (event) => {
    setOpcionSeleccionada(event.target.value);
  };


  const handleResponsableVisitaChangeChange = (event) => {
    setResponsableVisita(event.target.value);
  };
  const handleResponsableRecibirChangeChange = (event) => {
    setResponsableRecibir(event.target.value);
  };



  const [comments, setComments] = useState(Array(46).fill(''));
  const [observacion, setObservacion] = useState(Array(46).fill(''));
  const [fecha, setFecha] = useState(Array(46).fill(''));


  const evaluationData = [
    {
      category: 'A. INSTALACIONES FISICAS',
      questions: [
        {
          question: '1. ¿La compañía cuenta con una infraestructura adecuada y segura para llevar a cabo su actividad económica? (verifique las instalaciones físicas)',
          index: 0,
        },
        {
          question: '2. ¿Las áreas de manejo, almacenamiento, cargue y descargue de mercancías se encuentran debidamente delimitadas y se controla el acceso al personal autorizado?',
          index: 1,
        },
        {
          question: '3. ¿La compañía dispone de una central de monitoreo tanto dentro como fuera de las instalaciones para garantizar la seguridad del producto o servicio suministrado?',
          index: 2,
        },
        {
          question: '4. ¿La compañía cuenta con un sistema de vigilancia y seguridad implementado?',
          index: 3,
        },
        {
          question: '5. ¿Las bodegas de la compañía cuentan con cerramientos seguros para proteger la mercancía?',
          index: 4,
        },
        {
          question: '6. ¿La compañía tiene personal de vigilancia en las instalaciones?',
          index: 5,
        },
        {
          question: '7. ¿La compañía cuenta con controles de acceso a sus instalaciones (personas, vehículos)?',
          index: 6,
        },
        {
          question: '8. ¿Existen procedimientos para limpieza, orden y desinfección de áreas?',
          index: 7,
        },
        {
          question: '9. ¿Se cuenta con un programa de mantenimiento locativo?',
          index: 8,
        },
        {
          question: '10. ¿Existe un programa de control de plagas?',
          index: 9,
        },
      ],
    },
    {
      category: 'B. ASOCIADO DE NEGOCIO: PROVEEDORES',
      questions: [
        {
          question: '1. ¿La compañía tiene procesos escritos para la evaluación y selección de asociados de negocio? En caso afirmativo, por favor relacione el código del procedimiento respectivo.',
          index: 10,
        },
        {
          question: '2. ¿Se evidencia control y gestión del abastecimiento y capacidades para cumplir con los requisitos de los pedidos?',
          index: 11,
        },
        {
          question: '3. ¿Cuenta la compañía con un plan de contingencia y/o continuidad de negocio?',
          index: 12,
        },
        {
          question: '4. ¿Cuenta con un sistema de registro, análisis y atención de quejas y reclamos de los asociados de negocio?',
          index: 13,
        },
        {
          question: '5. ¿La compañía cuenta con pólizas de seguro todo riesgo para las mercancías transportadas y almacenadas que sean propiedad de TALSA?',
          index: 14,
        },
        {
          question: '6. ¿Cuenta con herramientas que permitan gestionar la trazabilidad y seguimiento de la entrega de los pedidos en tiempo real?',
          index: 15,
        },
        {
          question: '7. ¿Cuándo subcontrata alguno de sus procesos críticos relacionados con la cadena de suministros, realiza estudios de seguridad a estos asociados del negocio?',
          index: 16,
        },
        {
          question: '8. ¿El personal que maneja nuestras cargas es personal directo?',
          index: 17,
        },
      ],
    },
    {
      category: 'C. GESTIÓN DE COMPRAS',
      questions: [
        {
          question: '1. ¿Tiene establecidos controles en la recepción de sus materiales y/o servicios?',
          index: 18,
        },
        {
          question: '2. ¿Cuenta con planes de inspección, control, pruebas de conformidad y/o disposición para sus materiales y/o servicios? ',
          index: 19,
        },
        {
          question: '3. ¿Identifica adecuadamente los materiales comprados, aceptados, rechazados y en proceso de ser inspeccionados?',
          index: 20,
        },
        {
          question: '4. ¿Cuenta con un sistema de selección y evaluación de asociados de negocio que garantice la seguridad de su cadena de suministro?',
          index: 21,
        },
        {
          question: '5. ¿Tiene definidas disposiciones para el adecuado almacenamiento de sus materias primas, teniendo en cuenta las medidas de seguridad de la mercancía? (Ejemplo: contaminación, hurto, entre otras)',
          index: 22,
        },
        {
          question: '6. ¿Tiene implementadas medidas de seguridad orientadas a mitigar riesgos en la cadena de suministro (nacional o internacional)?',
          index: 24,
        },
        {
          question: '7. ¿Cuenta con procedimientos documentados para garantizar la integridad y seguridad de la carga en los procesos de manejo, almacenamiento y transporte?',
          index: 25,
        },
        {
          question: '8. ¿Tiene procedimientos documentados para garantizar que la información de despacho o recepción de carga sea veraz y esté protegida contra cambios, pérdidas o introducción de datos erróneos?',
          index: 26,
        },
      ],
    },
    {
      category: 'D. CONTROL DE LA PRODUCCIÓN, SEGURIDAD DEL PERSONAL Y PRESTACIÓN DEL SERVICIO',
      questions: [
        {
          question: '1. ¿Controla y documenta los parámetros de calidad del proceso durante la producción y/o prestación del servicio?',
          index: 27,
        },
        {
          question: '2. ¿Realiza pruebas de conformidad a los productos terminados y/o a la calidad del servicio según corresponda?',
          index: 28,
        },
        {
          question: '3. ¿Identifica adecuadamente el producto terminado aprobado, rechazado y en proceso de ser inspeccionado? Para el caso de servicios, ¿identifica antes, durante y posterior a la prestación del mismo que cumplan los estándares de calidad declarados en sus procedimientos?',
          index: 29,
        },
        {
          question: '4. ¿Cuenta con disposiciones para el manejo de su producto terminado no conforme? En el caso de servicios, ¿dispone de mecanismos para la gestión y mejora continua de los servicios que no se ejecuten según lo acordado?',
          index: 30,
        },
        {
          question: '5. ¿Cuenta con un programa de mantenimiento preventivo y realiza mantenimiento correctivo a la maquinaria o equipos utilizados en la producción o prestación de servicios que brinda a TALSA? Por favor, proporcionar registros que confirmen su cumplimiento.',
          index: 31,
        },
        {
          question: '6. ¿Tiene implementado un programa de calibración, revisión y mantenimiento de los equipos de medición asociados a la calidad de los productos o servicios que comercializa?',
          index: 32,
        },
        {
          question: '7. ¿Dispone de fichas técnicas y hojas de seguridad de los productos que comercializa, o protocolos de nivel de servicio para cada uno de los servicios que suministra a TALSA?',
          index: 33,
        },
        {
          question: '8. ¿Se tienen procedimientos para la selección del personal a vincular en la compañía, que incluyan medidas de seguridad?',
          index: 34,
        },
      ],
    },
    {
      category: 'E. GESTIÓN AMBIENTAL',
      questions: [
        {
          question: '1. ¿Tiene establecida una política de gestión ambiental y sostenibilidad?',
          index: 35,
        },
        {
          question: '2. ¿Su compañía cuenta con certificados, reconocimientos o convenios de gestión ambiental? En caso afirmativo, ¿cuáles?',
          index: 36,
        },
        {
          question: '3. ¿Cuenta con una matriz de impactos ambientales dentro de su organización? ¿Cuáles son los impactos más significativos?',
          index: 37,
        },
        {
          question: '4. ¿Cuenta con planes de acción para mitigar los impactos ambientales, objetivos y metas?',
          index: 38,
        },
        {
          question: '5. ¿Ha establecido procedimientos para atender posibles emergencias ambientales relacionadas con su actividad?',
          index: 39,
        },
      ],
    },
    {
      category: 'F. BENEFICIO E INTERÉS COLECTIVO',
      questions: [
        {
          question: '1. ¿Se tienen relaciones comerciales con empresas de origen local o que pertenezcan a mujeres y minorías?',
          index: 50,
        },
        {
          question: '2. ¿Se cuenta con un manual para los colaboradores de la compañía donde se apoye la inclusión de personas con distintas culturas, minorías étnicas, creencias religiosas diversas, con distintas orientaciones sexuales, capacidades físicas heterogéneas y diversidad de género?',
          index: 51,
        },
        {
          question: '3. ¿Qué beneficios se otorgan a los colaboradores? (Salarios justos, incentivos educativos, economicos, sociales, entre otros)',
          index: 52,
        },
        {
          question: '4. ¿Se realizan controles periodicos sobre la eficiencia de uso de energía, agua y desechos?',
          index: 53,
        },
        {
          question: '5. ¿Se incentivan las actividades de voluntariado, obras sociales y apoyo a fundaciones?',
          index: 54,
        }
      ]
    },
    {
      category: 'F. GESTIÓN DE SEGURIDAD',
      questions: [
        {
          question: '1. ¿Tiene implementado un sistema de gestión de la seguridad industrial y la seguridad y salud en el trabajo en toda su cadena de suministros y comercio exterior?',
          index: 40,
        },
        {
          question: '2. ¿Controla el embalaje de sus productos?',
          index: 41,
        },
        {
          question: '3. ¿La empresa controla y registra los medios de distribución (transporte) de sus productos?',
          index: 42,
        },
        {
          question: '4. ¿Identifica adecuadamente los productos según su peligrosidad?',
          index: 43,
        },
        {
          question: '5. ¿La compañía cuenta con código de conducta para asociados de negocio o que incluya este grupo de interés a través del cual se transmitan los valores y principios de la empresa?',
          index: 44,
        },
        {
          question: '6. ¿Cuenta con un sistema de autocontrol para la gestión, prevención y control de los riesgos asociados al lavado de activos, financiación del terrorismo (LA/FT) y otros riesgos en su cadena de suministros?',
          index: 45,
        },
        {
          question: '7. ¿La empresa cuenta con registros de los resultados de la identificación de peligros y la evaluación y valoración de riesgos, incluyendo actividades rutinarias y no rutinarias (IPVR)?',
          index: 46,
        },
        {
          question: '8. ¿Cuenta con el respectivo permiso o autorización de la entidad reglamentaria correspondiente para su funcionamiento (si aplica) ?',
          index: 47,
        },
        {
          question: '9. ¿Cuenta con uno o varios sistemas de gestión? En caso afirmativo, indique cuáles son.',
          index: 48,
        },
        {
          question: '10. ¿Cuenta con ceriticaciones de sistemas de gestion u otro reconocimiento relacionado?',
          index: 49,
        }
      ],
    },
  ];


  const asociados = [
    { tipo: 'FAVORABLE', puntaje: '> 80', accion: 'Enfatizar los puntos fuertes para que sostenga la posición y los no tan fuertes para que los mejore.', control: 'No requiere verificación' },
    { tipo: 'FAVORABLE CONDICIONAL', puntaje: '> 60 y <= 80', accion: 'Presentar los puntos débiles para que implemente acciones.', control: 'Verificación eventual a los tres meses' },
    { tipo: 'NO FAVORABLE', puntaje: '> 0 y <= 60', accion: 'Se le informa al asociado que no ha cumplido con los parámetros esperados para continuar contando con sus servicios NO FAVORABLE.', control: 'Estudiar los planes de acción enviados por el proveedor' },
  ];


  const [evaluations, setEvaluations] = useState(Array(46).fill(0));

  const handleEvaluationChange = (index, value) => {
    setEvaluations((prevEvaluations) => {
      const newEvaluations = [...prevEvaluations];
      newEvaluations[index] = value;
      if (value == 1) {
        document.getElementById("observacion" + index).style.display = "revert";
        document.getElementById("fecha" + index).style.display = "revert";
      } else {
        document.getElementById("observacion" + index).style.display = "none";
        document.getElementById("fecha" + index).style.display = "none";
      }
      return newEvaluations;
    });
  };

  const [totalPoints, setTotalPoints] = useState(0);

  const sumPoints = () => {
    const sum = evaluations.reduce((total, evaluation) => total + evaluation, 0);
    setTotalPoints(sum);
  };


  const handleCommentChange = (index, value) => {
    setComments((prevComments) => {
      const newComments = [...prevComments];
      newComments[index] = value;
      return newComments;
    });
  };
  const handleObservacionChange = (index, value) => {
    setObservacion((prevComments) => {
      const newComments = [...prevComments];
      newComments[index] = value;
      return newComments;
    });
  };
  const handleFechaChange = (index, value) => {
    setFecha((prevComments) => {
      const newComments = [...prevComments];
      newComments[index] = value;
      return newComments;
    });
  };








  const evaluationRef = useRef(null);




  return (
    <EvaluationContainer ref={evaluationRef} >
      <TableContainerStyled component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCellHead></TableCellHead>
              <TableCellHead></TableCellHead>
              <TableCellHead></TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellBody>
                <Typography>FECHA DE DILIGENCIAMIENTO</Typography>
                <TextField
                  type='date'
                  fullWidth
                  placeholder="Ingrese la fecha"
                  value={fechaDiligenciamiento}
                  onChange={handleFechaDiligenciamientoChange}
                />
              </TableCellBody>
              <TableCellBody>
                <Typography>PAÍS</Typography>
                <TextField
                  fullWidth
                  value={pais}
                  onChange={handlePaisChange}
                />
              </TableCellBody>
              <TableCellBody>
                <Typography>RAZON SOCIAL</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={razonSocial}
                  onChange={handleRazonSocialChange}
                />
              </TableCellBody>
            </TableRow>
            <TableRow>
              <TableCellBody>
                <Typography>DIRECCIÓN</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={direccion}
                  onChange={handleDireccionChange}
                />
              </TableCellBody>
              <TableCellBody>
                <Typography>¿AÑOS VINCULADO CON TALSA?</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={vinculosCiTalsa}
                  onChange={handleVinculosCiTalsaChange}
                />
              </TableCellBody>
              <TableCellBody>
                <Typography>CIUDAD</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={ciudad}
                  onChange={handleCiudadChange}
                />
              </TableCellBody>
            </TableRow>
            <TableRow>
              <TableCellBody>
                <Typography>TELEFONO</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={telefono}
                  onChange={handleTelefonoChangeChange}
                />
              </TableCellBody>
              <TableCellBody>
                <Typography>¿A CUÁNTO ASCIENDEN LAS COMPRAS EN PROMEDIO DEL ÚLTIMO AÑO?</Typography>
                <TextField
                  fullWidth
                  placeholder=""
                  value={promedio}
                  onChange={handlePromedioChangeChange}
                />
              </TableCellBody>

            </TableRow>
          </TableBody>
        </Table>
      </TableContainerStyled>

      <Select
        value={opcionSeleccionada}
        displayEmpty
        fullWidth
        onChange={handleOpcionSeleccionadaChange}
      >
        <MenuItem value="" disabled>
          -- PRODUCTO O SERVICIOS QUE SUMINISTRA A LA EMPRESA	--
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CONTACTO</TableCell>
              <TableCell>NOMBRE</TableCell>
              <TableCell>TELÉFONO</TableCell>
              <TableCell>EMAIL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Select
                    value={row.contacto}
                    displayEmpty
                    fullWidth
                    onChange={(e) =>
                      handleRowChange(index, 'contacto', e.target.value)
                    }
                  >
                    <MenuItem value="" disabled>
                      -- CONTACTO --
                    </MenuItem>
                    {optionsContacto.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={row.nombre}
                    onChange={(e) =>
                      handleRowChange(index, 'nombre', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={row.telefono}
                    onChange={(e) =>
                      handleRowChange(index, 'telefono', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={row.email}
                    onChange={(e) =>
                      handleRowChange(index, 'email', e.target.value)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={handleAddRow}>Añadir otra fila</Button>
      </TableContainer>

      <TableContainer component={Paper} style={{ width: "100%", border: "1px solid", margin: "auto", padding: "0px" }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>
                <h4>SISTEMA DE PUNTUACIÓN</h4>
              </TableCell>
              <TableCell>
                <h4>Categoría</h4>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                1
              </TableCell>
              <TableCell>
                No cumple
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                2
              </TableCell>
              <TableCell>
                Cumple parcialmente
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                3
              </TableCell>
              <TableCell>
                Cumple
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainerStyled component={Paper} style={{ maxHeight: "400px", maxWidth: "100%", overflowY: "auto" }}>
        <Table >
          <TableHead >
            <TableRow>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Categoría</TableCellHead>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Preguntas</TableCellHead>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Evaluación</TableCellHead>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Comentarios</TableCellHead>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Observación</TableCellHead>
              <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Fecha</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationData.map((category, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {category.questions.map((question, questionIndex) => (
                  <TableRow key={`${categoryIndex}-${questionIndex}`}>
                    {questionIndex === 0 && (
                      <TableCellHead style={{ textAlign: "center" }} rowSpan={category.questions.length}>{category.category}</TableCellHead>
                    )}

                    <TableCellBody>{question.question}</TableCellBody>
                    <TableCellBody>
                      <SelectFormControl>
                        <Select
                          value={evaluations[question.index]}
                          onChange={(event) => handleEvaluationChange(question.index, event.target.value)}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </SelectFormControl>

                    </TableCellBody>

                    <TableCellBody>
                      <TextareaAutosize
                        id={`comentario${question.index}`}
                        placeholder="Ingrese su comentario"
                        onChange={(event) => handleCommentChange(question.index, event.target.value)}
                      />
                    </TableCellBody>




                    <TableCellBody style={{ display: "none" }} id={"observacion" + question.index}>
                      <TextareaAutosize id={`Observación ${question.index}`}
                        placeholder="Ingrese su Observación"
                        onChange={(event) => handleObservacionChange(question.index, event.target.value)}
                      />

                    </TableCellBody>


                    <TableCellBody style={{ display: "none" }} id={"fecha" + question.index}>
                      <TextField id={`fecha ${question.index}`}
                        type='date'
                        placeholder="Ingrese la fecha"
                        onChange={(event) => handleFechaChange(question.index, event.target.value)}
                      />
                    </TableCellBody>

                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <Typography style={{ fontWeight: "bold", color: "red" }}  >Nota: Todos los númerales que contengan observaciones deberán contar con un plan de acción, el cual debe de ser documentado en los próximos tres(3) meses</Typography>
      <br></br>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell >Calificación</TableCell>
              <TableCell >Puntaje</TableCell>
              <TableCell >Acción a seguir</TableCell>
              <TableCell >Control</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {asociados.map((asociado, index) => (
              <TableRow key={index}>
                <TableCell>{asociado.tipo}</TableCell>
                <TableCell>{asociado.puntaje}</TableCell>
                <TableCell>{asociado.accion}</TableCell>
                <TableCell>{asociado.control}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6">Calificación: {((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)} %</Typography>
      <Typography variant="h6">Calificación: {
        (((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
          && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")
      }</Typography>


      <div>
        <TextField
          placeholder='RESPONSABLE DE EJECUTAR LA VISITA'
          style={{ width: "350px", padding: "15px" }}
          value={responsableVisita}
          onChange={(e) =>
            setResponsableVisita(e.target.value)
          }
        />
        <SignatureCanvas
          canvasProps={{
            width: 550,
            height: 150,
            style: { "border": "1px solid #000000" }
          }}
          minWidth={1}
          maxWidth={1}
          penColor="black"
          ref={sigPad}
        />
        <br /><Button onClick={() => {
          sigPad.current.clear()
          setFirmaFile(null)
        }}
          variant="contained"
          component="label"
          color="primary"
          style={{ margin: "10px" }}>
          Limpiar     </Button>
        <Button variant="contained"
          component="label"
          color="primary"
          style={{ margin: "10px" }} onClick={() => {
            sumPoints()
            const canvas = sigPad.current.getCanvas();
            const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
            setFirmaFile(base64Data)
            swal("Gracias", "Firma ingrasada con exito", "success");
          }}>Guardar</Button>
      </div>



      <div>
        <TextField
          placeholder='RESPONSABLE DE RECIBIR LA VISITA'
          style={{ width: "350px", padding: "15px" }}
          value={responsableRecibir}
          onChange={(e) =>
            setResponsableRecibir(e.target.value)
          }
        />
        <SignatureCanvas
          canvasProps={{
            width: 550,
            height: 150,
            style: { "border": "1px solid #000000" }
          }}
          minWidth={1}
          maxWidth={1}
          penColor="black"
          ref={sigPad2}
        />
        <br /><Button onClick={() => {
          sigPad2.current.clear()
          setFirmaFile2(null)
        }}
          variant="contained"
          component="label"
          color="primary"
          style={{ margin: "10px" }}>
          Limpiar     </Button>
        <Button variant="contained"
          component="label"
          color="primary"
          style={{ margin: "10px" }} onClick={() => {
            sumPoints()
            const canvas = sigPad2.current.getCanvas();
            const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
            setFirmaFile2(base64Data)
            swal("Gracias", "Firma ingrasada con exito", "success");
          }}>Guardar</Button>
      </div>

      <Alert severity="warning">Debe de firmar el formulario y luego darle al boton enviar información.</Alert>    
      <Button variant="contained" color="primary" onClick={() => {

        const tableData = [];
        // Recorrer las filas de la tabla
        evaluationData.forEach((category, categoryIndex) => {
          const categoryData = {
            category: category.category,
            questions: [],
          };
          category.questions.forEach((question, questionIndex) => {
            // Obtener los valores de la pregunta, evaluación, comentario y observación
            const questionData = {
              question: question.question,
              evaluation: evaluations[question.index] || "",
              comment: document.getElementById(`comentario${question.index}`).value || "",
              observation: document.getElementById(`Observación ${question.index}`).value || "",
              fecha: document.getElementById(`fecha ${question.index}`).value || "",
            };
            // Agregar los valores al array de preguntas
            categoryData.questions.push(questionData);
          });
          // Agregar los datos de la categoría al array principal
          tableData.push(categoryData);

        });

        const camposRequeridos = [
          { campo: 'fechaDiligenciamiento', valor: fechaDiligenciamiento, mensaje: 'Fecha de diligenciamiento' },
          { campo: 'razonSocial', valor: razonSocial, mensaje: 'Razón social' },
          { campo: 'telefono', valor: telefono, mensaje: 'Teléfono' },
          { campo: 'direccion', valor: direccion, mensaje: 'Dirección' },
          { campo: 'ciudad', valor: ciudad, mensaje: 'Ciudad' },
          { campo: 'vinculosCiTalsa', valor: vinculosCiTalsa, mensaje: 'Tiempo de vinculación con Talsa' },
          { campo: 'promedio', valor: promedio, mensaje: 'Compras en el último año' },
          { campo: 'opcionSeleccionada', valor: opcionSeleccionada, mensaje: 'Suministros' },
          { campo: 'pais', valor: pais, mensaje: 'País' },
        ];

        for (let campo of camposRequeridos) {
          if (!campo.valor || campo.valor.trim() === '') {
            swal({
              title: 'Se debe de firmar el documento!',
              text: `Campo faltante: ${campo.mensaje}`,
              icon: 'warning',
            });
            setLoading(false)
            return false;
          }
        }



        if (firmaFile == null || firmaFile2 == null) {
          swal("Se debe de firmar el documento!", "Firma , guarda y envia!", "warning");
          return;
        }

        const jsonData = JSON.stringify(tableData);
        const jsonData1 = JSON.stringify(rows);
        var raw = JSON.stringify({
          "datos": `${jsonData}`,
          "Dfechadilencia": `${fechaDiligenciamiento}`,
          "Drazonsocial": `${razonSocial}`,
          "DTelefono": `${telefono}`,
          "DDireccion": `${direccion}`,
          "DCiudad": `${ciudad}`,
          "DTiempoTalsa": `${vinculosCiTalsa}`,
          "Dcomprasultimosano": `${promedio}`,
          "Dsuministran": `${opcionSeleccionada}`,
          "DPais": `${pais}`,
          "Dfirma1": `${firmaFile}`,
          "Dfirma2": `${firmaFile2}`,
          "DCalificacionnumero": `${((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)}`,
          "DCalificacio": `${(((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
            && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")
            }`,
          "Dresponsable": `${responsableVisita}`,
          "Dresponsable2": `${responsableRecibir}`,
          "datos2": `${jsonData1}`,
        });
        console.log(firmaFile)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://consumos.talsa.com.co/api/talsa/pdf/v1/pdfvisita", requestOptions)
          .then(response => {
            if (response.status == 200) {
              return response.text(); // Return the response text to the next then
            } else {
              setLoading(false)
              swal("Error", "El documento no fue creado con exito", "error");
              throw new Error('El documento no fue creado con exito');
            }
          })
          .then(responseText => {
            const responseJson = JSON.parse(responseText);
            const base64 = responseJson.base64;
            console.log(base64)
            TareasProveedor(localStorage.Proveedor, fechaDiligenciamiento, pais, razonSocial, direccion, vinculosCiTalsa, ciudad, opcionSeleccionada,
              jsonData, localStorage.Tproveedor).then((result) => {
                ingresoDOCXWorkmanagerFlujo(base64, result.workflowId, "visita").then(() => {
                  tableData.forEach(element => {
                    element.questions.forEach(elementv2 => {
                      if (elementv2.observation != "") {
                        TareasProveedorObseracion(localStorage.Proveedor, elementv2.comment, elementv2.observation, result.BarCode, elementv2.question, elementv2.fecha).then((v1) => {
                        })
                      }
                    });
                  });
                  swal("Exitoso", "El documento fue creado con exito", "success");
                });
              })
          }).then(result => console.log(result))
          .catch(error => console.log('error', error));


        /*  generatePDF(fechaDiligenciamiento, rows, tableData, ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2), firmaFile, firmaFile2, pais, razonSocial, telefono, direccion, vinculosCiTalsa, ciudad, promedio, opcionSeleccionada, responsableVisita, responsableRecibir).then((resultv2) => {
  
          })*/
      }} >
        Enviar información
      </Button>
    </EvaluationContainer >
  );
}
