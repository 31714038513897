import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Stack, IconButton, InputAdornment, TextField, Container, Button } from '@mui/material';
import { getIDWorkmanager, Alert, encrypt } from '../Componentes/FunctionLogin.js'
import '../css/login.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Document, Page } from 'react-pdf';
import Popup from '../Componentes/popup'
import CardFoto from '../Componentes/Carpetas/CardFotos.js'
import WhatsAppv1 from '../Componentes/whatsapp'
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import proveedorlogo from '../recursos/proveedor.jpg'
import proveedorlogoen from '../recursos/proveedoren.jpg'
import comoserproveedor from '../recursos/comoserproveedor.jpg'
import comoserproveedoren from '../recursos/comoserproveedoren.jpg'
import informacion from '../recursos/informacion.jpg'
import informacionen from '../recursos/informacionen.jpg'
import bogota from '../recursos/bogota.png'
import bucaramanga from '../recursos/bucaramanga.png'
import cali from '../recursos/cali.png'
import cucuta from '../recursos/cucuta.png'
import tutorial from '../recursos/tutorial.jpg'
import tutorialen from '../recursos/tutorialen.jpg'
import dosquebrada from '../recursos/dosquebrada.png'
import itagui from '../recursos/itagui.png'
import barranquilla from '../recursos/barranquilla.png'
import headerjpg from '../recursos/header.jpg'
import logopng from '../recursos/logocompleto.png'
import headergif from '../recursos/header.gif'
import { wait } from '@testing-library/user-event/dist/utils/index.js';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'
import CambiarIdioma from './cambiaridioma.js'


export default function Login() {

    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [tipoDeUsuario, setTipoDeUsuario] = useState("PROVEEDOR");
    const [showPassword, setShowPassword] = useState(false);
    const [header, setHeader] = useState(true);
    const [Usuario, setUsuario] = useState();
    const [Contrasena, setContrasena] = useState();
    const [vector, setVector] = useState();
    const [cargar, setCargar] = useState(0);
    const [base64] = useState("");


    React.useEffect(() => {
    }, [vector])

    React.useEffect(() => {
        setTimeout(() => {
            setHeader(false);
        }, 3000);
    }, []);

    return (
        <Container className='Maestro' maxWidth={"0px"}>
            <Grid item xs={12}>
                <Grid container alignItems="center" style={{ background: "#BF2642", width: "100%", height: "50px", maxWidth: "100%" }}>
                    <Grid item xs={12} md={12}>
                        <b style={{ fontSize: "17px", color: "white" }}>{t("login.titulo")}</b>
                    </Grid>
                </Grid>
                <Grid container style={{ width: "100%", position: "relative" }}>
                    <Grid item xs={12} md={3}>
                        <img src={logopng} className='imgheader' height={50} style={{
                            width: "25%", height: "auto", padding: "20px"
                        }} alt="Logo" />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ textAlign: "left", paddingLeft: "30px" }}>
                        <p style={{ fontSize: "12px", padding: "20px" }}>
                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{t("login.bienvenido")}</p>
                            <p style={{ textAlign: "center" }}>{t("login.bienvenido1")}</p>
                            <p style={{ textAlign: "center" }}>{t("login.bienvenido2")}</p>
                            <p style={{ textAlign: "center" }}>{t("login.bienvenido3")}</p>
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Container >
            </Container>
            <Container className='content' style={{ display: "none" }} maxWidth={"0px"} >
                <Container className='ContenedorLogin' style={{ backgroundColor: "rgb(250, 250, 250) " }} >
                    <p>{t("login.login")} </p>
                    <Container style={{ padding: "15px" }}>
                        <Stack spacing={3}>
                            <TextField className='txtUsuario' name="email" label={t("login.usuario")} value={Usuario} onChange={(e) => {
                                setUsuario(e.target.value);
                            }} />
                            <TextField
                                className='txtContrase'
                                name="Contraseña"
                                label={t("login.contraseña")} value={Contrasena} onChange={(e) => {
                                    setContrasena(e.target.value);
                                }}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {

                                            }} edge="end">
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <br />

                        {cargar === 1 ? <Container><CircularProgress disableShrink /> <p>{t("login.validando")}</p></Container>
                            :
                            <Button style={{ backgroundColor: "rgba(189, 31, 52, 0.96)" }} size="small" className='btnIngresar' id="btnIngresar" variant="contained" onClick={() => {
                                try {
                                    setCargar(1)
                                    Alert(Usuario, Contrasena).then((result) => {
                                        if (result.length > 0) {
                                            localStorage.setItem("Proveedor", result[0].Proveedor)
                                            localStorage.setItem("Tproveedor", result[0].Tproveedor)
                                            localStorage.setItem("Nit", result[0].Nit)
                                            localStorage.setItem("TipoProveedor", result[0].tipo)
                                            localStorage.setItem("Pais", result[0].Pais)
                                            localStorage.setItem("Ciudad", result[0].Ciudad)
                                            localStorage.setItem("Telefono", result[0].Telefono)

                                            getIDWorkmanager(result[0].Proveedor).then((resultv2) => {
                                                if (resultv2[6].Value !== "") {
                                                    if (Contrasena === resultv2[6].Value) {
                                                        if (resultv2[7].Value != "") {
                                                            encrypt("COLABORADORES").then((result) => {
                                                                localStorage.setItem("TipoDeUsuario", result)
                                                            })
                                                        } else {
                                                            encrypt("PROVEEDOR").then((result) => {
                                                                localStorage.setItem("TipoDeUsuario", result)
                                                            })
                                                        }
                                                        //COLABORADORES
                                                        localStorage.setItem("Radicado", resultv2[14].Value)
                                                        localStorage.setItem("workmanagerTipoUsuario", resultv2[7].Value)
                                                        localStorage.setItem("Categoria", resultv2[8].Value)
                                                        localStorage.setItem("Criticidad", resultv2[9].Value)
                                                        localStorage.setItem("Email", resultv2[5].Value)
                                                        localStorage.setItem("Direcc", resultv2[3].Value)
                                                        encrypt("Conectado" + Math.random().toFixed(2)).then((session) => {
                                                            // Cookies.set('sessionId', session, { secure: true, sameSite: 'strict' });
                                                            Cookies.set('sessionId', session, { sameSite: 'strict' });
                                                        });
                                                        localStorage.setItem("showOrderPopup", true);
                                                        navigate('/proveedores/órdenes');
                                                    } else {
                                                        setCargar(0)
                                                        swal("Datos de Logueo incorrectos!", "Valide la información!", "warning");
                                                    }
                                                } else {
                                                    swal("Error!", "Este usuario no tiene parametrizado una contraseña!", "warning");
                                                    setCargar(0);
                                                }
                                            })
                                        } else {
                                            setCargar(0)
                                            swal("Datos de Logueo incorrectos!", "Valide la información!", "warning");
                                        }
                                    });
                                } catch (error) {
                                }
                            }}>
                                {t("login.ingresar")}
                            </Button>}
                        <Button onClick={() => {
                            navigate('/proveedores/contraseña');
                        }}> {t("login.recuperar")} 
                         </Button>
                        <br />
                    </Container>
                    <CambiarIdioma />
                </Container>
            </Container>
            <Container className='content' maxWidth={"0px"}>
                <Container className='ContenedorImagen' maxWidth={"10px"}  >
                    <Container className='hijosImagen' >
                        <Paper elevation={1} >
                            <CardFoto text={"Conoce el manual de proveedores CI TALSA"} foto={localStorage.lenguaje=="es"?proveedorlogo:proveedorlogoen} url={"https://drive.google.com/file/d/1frwotLTJeq9xqCm6nSZbQG-jvBV_7e3U"} ></CardFoto>
                        </Paper>
                    </Container>
                    <Container className='hijosImagen'>
                        <Paper elevation={1}>
                            <CardFoto text={"¿Quieres ser proveedor de CI TALSA?"} foto={localStorage.lenguaje=="es"?comoserproveedor:comoserproveedoren} url={"https://docs.google.com/forms/d/1PDT_AZoTtqCwmAsruqpzg41e96YVwfNVXIWASZrkmVE"}></CardFoto>
                        </Paper>
                    </Container>
                    <Container className='hijosImagen'>
                        <Paper elevation={1}>
                            <CardFoto text={"Información para proveedores CI TALSA"} foto={localStorage.lenguaje=="es"?informacion:informacionen}  url={"https://drive.google.com/file/d/1X2RnUsTESWlEEik9kjXk69xwhm-WKjcE/view?usp=sharing"} ></CardFoto>
                        </Paper>
                    </Container>
                    <Container className='hijosImagen'>
                        <Paper elevation={1}>
                            <CardFoto text={"Cómo usar el portal"} foto={localStorage.lenguaje=="es"?tutorial:tutorialen}  url={"https://www.youtube.com/watch?v=T8Ur5naLuiQ&list=PL4GjjWz8eNze-KnqZzInF35jOrU3LWTAy"}></CardFoto>
                        </Paper>
                    </Container>
                </Container>
            </Container>
            <Container className='content'>

                <Container className='hijosfooterv2' width={"10px"} style={{ margin: "20px" }}>
                    <WhatsAppv1></WhatsAppv1>
                </Container>
                <Container className='footer' maxWidth={"10px"} style={{ display: "flex" }}>
                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps/place/CI+Talsa+Medell%C3%ADn/@6.1967474,-75.5894503,17z/data=!4m5!3m4!1s0x8e46826fc2982a93:0xdd96fedde788b5c9!8m2!3d6.1967474!4d-75.5872616?shorturl=1" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={itagui} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>
                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps/dir//4.6316945,-74.1238324/@4.631908,-74.123822,17z?hl=es" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={bogota} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>

                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps?ll=3.472033,-76.508979&z=16&t=m&hl=es&gl=CO&mapclient=embed&daddr=CI+Talsa+Cali+Cra.+1+%2345a71+Cali,+Valle+del+Cauca@3.472033,-76.50897859999999" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={cali} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>

                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps?ll=11.005717,-74.82637&z=16&t=m&hl=es&gl=CO&mapclient=embed&daddr=CI+Talsa+Barranquilla+Cl.+93+%23No+46+-+168+Barranquilla,+Atl%C3%A1ntico@11.0057165,-74.8263704" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={barranquilla} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>

                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://maps.app.goo.gl/33eXiLzLNAYC6AW6A" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={dosquebrada} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>
                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps/dir//Cl+Talsa+Bucaramanga,+Cra.+27+%2318+-+57,+Bucaramanga,+Santander/@7.1302412,-73.1188509,18.79z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8e681564c91101bd:0x993eb54990aa9772!2m2!1d-73.1195639!2d7.1308223?hl=es" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={bucaramanga} style={{ width: "170px" }} alt="maps" />
                        </Typography> </a>
                    </Container>

                    <Container className='hijosfooter'>
                        <a target="_blank" href="https://www.google.com/maps/dir//7.8917008,-72.5021452/@7.891701,-72.502145,16z?hl=es" className='afooter' data-bss-pl="active" > <Typography mt={0} variant="caption" >
                            <img src={cucuta} style={{ width: "170px" }} alt="Cucuta" />
                        </Typography> </a>
                    </Container>

                </Container>
            </Container>
        </Container>
    )
}
