import React, { useState, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Paper,
    Typography,
    makeStyles,
    Alert
} from '@mui/material';
import styled from 'styled-components';
import { Visibility } from '@mui/icons-material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { EnviarCorreopdf, TareasProveedor, ingresoPDFWorkmanagerFlujo, TareasProveedorObseracion, ingresoDOCXWorkmanagerFlujo } from '../FunctionLogin'
import { generatePDF } from '../FuncionPDFen'
import SignatureCanvas from "react-signature-canvas";
import swal from 'sweetalert';



const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EvaluationTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const TableContainerStyled = styled(TableContainer)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const TableCellHead = styled(TableCell)`
  font-weight: bold;
  background-color: #f5f5f5;
`;

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  padding: 12px;
`;

const SelectFormControl = styled(FormControl)`
  min-width: 80px;
`;

export default function EvaluationVisita() {
    const optionsContacto = [
        'SALES',
        'QUALITY',
        'INTERNATIONAL TRADE',
        'OTHER'
    ];


    const sigPad = useRef({});
    const sigPad2 = useRef({});
    const initialRow = {
        contacto: '',
        nombre: '',
        telefono: '',
        email: '',
    };



    const [loading, setLoading] = useState(false);
    const [firmaFile, setFirmaFile] = useState(null);
    const [firmaFile2, setFirmaFile2] = useState(null);
    const [rows, setRows] = useState([initialRow]);
    const handleAddRow = () => {
        setRows([...rows, initialRow]);
    };


    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const options = [
        'NATIONAL TRANSPORTATION SERVICES',
        'IT SERVICES',
        'MECHANIZED OR METAL-MECHANICAL SERVICES',
        'CUSTOMS AGENCY SERVICES',
        'INTERNATIONAL TRANSPORTATION SERVICES',
        'PACKAGING SERVICES',
        'ENGINEERING SERVICES',
        'LEGAL SERVICES',
        'ACCOUNTING AND TAX SERVICES',
        'LOGISTICS SERVICES',
        'RAW MATERIALS SUPPLIES',
        'PACKAGING MATERIALS SUPPLIES',
        'SPARE PARTS SUPPLIES',
        'MACHINERY AND EQUIPMENT SUPPLIES FOR SALE',
        'CONSUMABLES AND ACCESSORIES SUPPLIES FOR SALE',
        'STORAGE'
    ];


    const [fechaDiligenciamiento, setFechaDiligenciamiento] = useState('');

    const [responsableVisita, setResponsableVisita] = useState('');
    const [responsableRecibir, setResponsableRecibir] = useState('');

    const [pais, setPais] = useState(localStorage.Pais);
    const [razonSocial, setRazonSocial] = useState(localStorage.Tproveedor);
    const [direccion, setDireccion] = useState(localStorage.Direcc);
    const [vinculosCiTalsa, setVinculosCiTalsa] = useState('');
    const [ciudad, setCiudad] = useState(localStorage.Ciudad);
    const [telefono, setTelefono] = useState(localStorage.Telefono);
    const [promedio, setPromedio] = useState('');
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
    const [opcionSeleccionadaContacto, setOpcionSeleccionadaContacto] = useState('');

    const handleFechaDiligenciamientoChange = (event) => {
        setFechaDiligenciamiento(event.target.value);
    };

    const handlePaisChange = (event) => {
        setPais(event.target.value);
    };

    const handleRazonSocialChange = (event) => {
        setRazonSocial(event.target.value);
    };

    const handleDireccionChange = (event) => {
        setDireccion(event.target.value);
    };

    const handleVinculosCiTalsaChange = (event) => {
        setVinculosCiTalsa(event.target.value);
    };

    const handleCiudadChange = (event) => {
        setCiudad(event.target.value);
    };
    const handleTelefonoChangeChange = (event) => {
        setTelefono(event.target.value);
    };
    const handlePromedioChangeChange = (event) => {
        setPromedio(event.target.value);
    };

    const handleOpcionSeleccionadaContactoChange = (event) => {
        setOpcionSeleccionadaContacto(event.target.value);
    };
    const handleOpcionSeleccionadaChange = (event) => {
        setOpcionSeleccionada(event.target.value);
    };


    const handleResponsableVisitaChangeChange = (event) => {
        setResponsableVisita(event.target.value);
    };
    const handleResponsableRecibirChangeChange = (event) => {
        setResponsableRecibir(event.target.value);
    };



    const [comments, setComments] = useState(Array(46).fill(''));
    const [observacion, setObservacion] = useState(Array(46).fill(''));
    const [fecha, setFecha] = useState(Array(46).fill(''));

    const evaluationData = [
        {
            category: 'FACILITIES',
            questions: [
                {
                    question: '1. Does the company have adequate and secure infrastructure to carry out its economic activity? (Check physical facilities)',
                    index: 0,
                },
                {
                    question: '2. Are the areas for handling, storage, loading, and unloading of goods properly delimited, and access is controlled for authorized personnel?',
                    index: 1,
                },
                {
                    question: '3. Does the company have monitoring centers both inside and outside the facilities to guarantee the security of the supplied product or service?',
                    index: 2,
                },
                {
                    question: '4. Does the company have an implemented surveillance and security system?',
                    index: 3,
                },
                {
                    question: '5. Do the company warehouses have secure enclosures to protect the merchandise?',
                    index: 4,
                },
                {
                    question: '6. Does the company have security personnel at the facilities?',
                    index: 5,
                },
                {
                    question: '7. Are there access controls to the facilities (people, vehicles)?',
                    index: 6,
                },
                {
                    question: '8. Are there procedures for cleaning, order, and disinfection of areas?',
                    index: 7,
                },
                {
                    question: '9. Is there a locative maintenance program?',
                    index: 8,
                },
                {
                    question: '10. Is there a pest control program?',
                    index: 9,
                },
            ],
        },
        {
            category: 'BUSINESS ASSOCIATE: SUPPLIERS',
            questions: [
                {
                    question: '1. Does the company have written processes for the evaluation and selection of business associates? If so, please provide the respective procedure code.',
                    index: 10,
                },
                {
                    question: '2. Is there evidence of control and management of supply and capabilities to meet order requirements?',
                    index: 11,
                },
                {
                    question: '3. Does the company have a business continuity and/or contingency plan?',
                    index: 12,
                },
                {
                    question: '4. Does it have a system to register, analyze, and address complaints and claims from business associates?',
                    index: 13,
                },
                {
                    question: '5. Does the company have all-risk insurance policies for the goods transported and stored that are owned by TALSA?',
                    index: 14,
                },
                {
                    question: '6. Are there tools in place to manage traceability and real-time monitoring of order delivery?',
                    index: 15,
                },
                {
                    question: '7. When subcontracting any of its critical processes related to the supply chain, does it conduct security studies on these business associates?',
                    index: 16,
                },
                {
                    question: '8. Is the personnel handling our shipments direct employees?',
                    index: 17,
                },
            ],
        },
        {
            category: 'PURCHASING',
            questions: [
                {
                    question: '1. Are there established controls in the receipt of materials and/or services?',
                    index: 18,
                },
                {
                    question: '2. Are there inspection, control, conformity testing, and/or disposition plans for materials and/or services?',
                    index: 19,
                },
                {
                    question: '3. Is the identification of purchased, accepted, rejected, and in-process materials adequate?',
                    index: 20,
                },
                {
                    question: '4. Does it have a system for the selection and evaluation of business associates to guarantee the security of the supply chain?',
                    index: 21,
                },
                {
                    question: '5. Are there provisions for the appropriate storage of raw materials, considering merchandise security measures? (e.g., contamination, theft, among others)',
                    index: 22,
                },
                {
                    question: '6. Are there implemented security measures aimed at mitigating risks in the supply chain (national or international)?',
                    index: 24,
                },
                {
                    question: '7. Are there documented procedures to ensure the integrity and security of the cargo in handling, storage, and transportation processes?',
                    index: 25,
                },
                {
                    question: '8. Are there documented procedures to ensure that dispatch or cargo reception information is accurate and protected against changes, losses, or input of erroneous data?',
                    index: 26,
                },
            ],
        },
        {
            category: 'PRODUCTION CONTROL, PERSONNEL SAFETY, AND SERVICE PROVISION',
            questions: [
                {
                    question: '1. Does it control and document the quality parameters of the process during production and/or service provision?',
                    index: 27,
                },
                {
                    question: '2. Does it conduct conformity tests on finished products and/or service quality as appropriate?',
                    index: 28,
                },
                {
                    question: '3. Does it properly identify the approved, rejected, and in-process finished product? For services, does it identify before, during, and after the provision of the service that meets the declared quality standards in its procedures?',
                    index: 29,
                },
                {
                    question: '4. Does it have provisions for handling non-conforming finished products? For services, does it have mechanisms for the management and continuous improvement of services that do not execute as agreed?',
                    index: 30,
                },
                {
                    question: '5. Does it have a preventive maintenance program and perform corrective maintenance on machinery or equipment used in the production or provision of services to TALSA? Please provide records confirming compliance.',
                    index: 31,
                },
                {
                    question: '6. Is there an implemented calibration, review, and maintenance program for measuring equipment associated with the quality of the products or services it sells?',
                    index: 32,
                },
                {
                    question: '7. Does it have technical data sheets and safety data sheets for the products it sells, or service level protocols for each of the services supplied to TALSA?',
                    index: 33,
                },
                {
                    question: '8. Are there procedures for the selection of personnel to join the company, including security measures?',
                    index: 34,
                },
            ],
        },
        {
            category: 'ENVIRONMENTAL MANAGEMENT',
            questions: [
                {
                    question: '1. Does it have an environmental management and sustainability policy?',
                    index: 35,
                },
                {
                    question: '2. Does your company have certificates, recognitions, or environmental management agreements? If so, which ones?',
                    index: 36,
                },
                {
                    question: '3. Does it have a matrix of environmental impacts within your organization? What are the most significant impacts?',
                    index: 37,
                },
                {
                    question: '4. Does it have action plans to mitigate environmental impacts, objectives, and goals?',
                    index: 38,
                },
                {
                    question: '5. Has it established procedures to address possible environmental emergencies related to its activity?',
                    index: 39,
                },
            ],
        },
        {
            category: 'SECURITY',
            questions: [
                {
                    question: '1. Does it have an implemented industrial safety and occupational health management system throughout its supply chain and foreign trade?',
                    index: 40,
                },
                {
                    question: '2. Does it control the packaging of its products?',
                    index: 41,
                },
                {
                    question: '3. Does the company control and record the distribution (transport) means of its products?',
                    index: 42,
                },
                {
                    question: '4. Does it properly identify products according to their hazardousness?',
                    index: 43,
                },
                {
                    question: '5. Does the company have a code of conduct for business associates or that includes this stakeholder group through which the values and principles of the company are transmitted?',
                    index: 44,
                },
                {
                    question: '6. Does it have a self-control system for the management, prevention, and control of risks associated with money laundering, terrorism financing (ML/TF), and other risks in its supply chain?',
                    index: 45,
                },
                {
                    question: '7. Does the company have records of the results of hazard identification and risk assessment and valuation, including routine and non-routine activities (HIRAV)?',
                    index: 46,
                },
            ],
        },
    ];


    const asociados = [
        { tipo: 'FAVORABLE', puntaje: '> 80', accion: 'Emphasize the strengths to maintain the position and the not-so-strong points to improve.', control: 'No verification required' },
        { tipo: 'CONDITIONALLY FAVORABLE', puntaje: '> 60 and <= 80', accion: 'Present the weak points for the associate to implement actions.', control: 'Eventual verification after three months' },
        { tipo: 'UNFAVORABLE', puntaje: '> 0 and <= 60', accion: 'The associate is informed that they have not met the expected parameters to continue having their services UNFAVORABLE.', control: 'Study the action plans submitted by the supplier' },
    ];


    const [evaluations, setEvaluations] = useState(Array(46).fill(0));

    const handleEvaluationChange = (index, value) => {
        setEvaluations((prevEvaluations) => {
            const newEvaluations = [...prevEvaluations];
            newEvaluations[index] = value;
            if (value == 1) {
                document.getElementById("observacion" + index).style.display = "revert";
                document.getElementById("fecha" + index).style.display = "revert";
            } else {
                document.getElementById("observacion" + index).style.display = "none";
                document.getElementById("fecha" + index).style.display = "none";
            }
            return newEvaluations;
        });
    };

    const [totalPoints, setTotalPoints] = useState(0);

    const sumPoints = () => {
        const sum = evaluations.reduce((total, evaluation) => total + evaluation, 0);
        setTotalPoints(sum);
    };


    const handleCommentChange = (index, value) => {
        setComments((prevComments) => {
            const newComments = [...prevComments];
            newComments[index] = value;
            return newComments;
        });
    };
    const handleObservacionChange = (index, value) => {
        setObservacion((prevComments) => {
            const newComments = [...prevComments];
            newComments[index] = value;
            return newComments;
        });
    };

    const handleFechaChange = (index, value) => {
        setFecha((prevComments) => {
            const newComments = [...prevComments];
            newComments[index] = value;
            return newComments;
        });
    };




    const evaluationRef = useRef(null);



    return (
        <EvaluationContainer ref={evaluationRef} >
            <TableContainerStyled component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead></TableCellHead>
                            <TableCellHead></TableCellHead>
                            <TableCellHead></TableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCellBody>
                                <Typography>FILLING DATE</Typography>
                                <TextField
                                    type='date'
                                    fullWidth
                                    placeholder="Enter the date"
                                    value={fechaDiligenciamiento}
                                    onChange={handleFechaDiligenciamientoChange}
                                />
                            </TableCellBody>
                            <TableCellBody>
                                <Typography>COUNTRY</Typography>
                                <TextField
                                    fullWidth
                                    value={pais}
                                    onChange={handlePaisChange}
                                />
                            </TableCellBody>
                            <TableCellBody>
                                <Typography>COMPANY NAME</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={razonSocial}
                                    onChange={handleRazonSocialChange}
                                />
                            </TableCellBody>
                        </TableRow>
                        <TableRow>
                            <TableCellBody>
                                <Typography>ADDRESS</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={direccion}
                                    onChange={handleDireccionChange}
                                />
                            </TableCellBody>
                            <TableCellBody>
                                <Typography>YEARS LINKED WITH TALSA</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={vinculosCiTalsa}
                                    onChange={handleVinculosCiTalsaChange}
                                />
                            </TableCellBody>
                            <TableCellBody>
                                <Typography>CITY</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={ciudad}
                                    onChange={handleCiudadChange}
                                />
                            </TableCellBody>
                        </TableRow>
                        <TableRow>
                            <TableCellBody>
                                <Typography>PHONE</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={telefono}
                                    onChange={handleTelefonoChangeChange}
                                />
                            </TableCellBody>
                            <TableCellBody>
                                <Typography>AVERAGE PURCHASES IN THE LAST YEAR</Typography>
                                <TextField
                                    fullWidth
                                    placeholder=""
                                    value={promedio}
                                    onChange={handlePromedioChangeChange}
                                />
                            </TableCellBody>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainerStyled>


            <Select
                value={opcionSeleccionada}
                displayEmpty
                fullWidth
                onChange={handleOpcionSeleccionadaChange}
            >
                <MenuItem value="" disabled>
                    -- PRODUCT OR SERVICES SUPPLIED TO THE COMPANY --
                </MenuItem>

                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>


            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>CONTACT</TableCell>
                            <TableCell>NAME</TableCell>
                            <TableCell>PHONE</TableCell>
                            <TableCell>EMAIL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Select
                                        value={row.contact || ''}
                                        displayEmpty
                                        fullWidth
                                        onChange={(e) =>
                                            handleRowChange(index, 'contact', e.target.value)
                                        }
                                    >
                                        <MenuItem value="" disabled>
                                            -- CONTACT --
                                        </MenuItem>
                                        {optionsContacto.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>

                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.name}
                                        onChange={(e) =>
                                            handleRowChange(index, 'name', e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.phone}
                                        onChange={(e) =>
                                            handleRowChange(index, 'phone', e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.email}
                                        onChange={(e) =>
                                            handleRowChange(index, 'email', e.target.value)
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button onClick={handleAddRow}>Add Another Row</Button>
            </TableContainer>
            <TableContainer component={Paper} style={{ width: "100%", border: "1px solid", margin: "auto", padding: "0px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <h4>SCORING SYSTEM</h4>
                            </TableCell>
                            <TableCell>
                                <h4>Category</h4>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                1
                            </TableCell>
                            <TableCell>
                                Does not meet
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                2
                            </TableCell>
                            <TableCell>
                                Partially meets
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                3
                            </TableCell>
                            <TableCell>
                                Meets
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <TableContainerStyled component={Paper} style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Category</TableCellHead>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Questions</TableCellHead>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Evaluation</TableCellHead>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Comments</TableCellHead>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Observation</TableCellHead>
                            <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Fecha</TableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {evaluationData.map((category, categoryIndex) => (
                            <React.Fragment key={categoryIndex}>
                                {category.questions.map((question, questionIndex) => (
                                    <TableRow key={`${categoryIndex}-${questionIndex}`}>
                                        {questionIndex === 0 && (
                                            <TableCellHead style={{ textAlign: "center" }} rowSpan={category.questions.length}>{category.category}</TableCellHead>
                                        )}
                                        <TableCellBody>{question.question}</TableCellBody>
                                        <TableCellBody>
                                            <SelectFormControl>
                                                <Select
                                                    value={evaluations[question.index]}
                                                    onChange={(event) => handleEvaluationChange(question.index, event.target.value)}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                </Select>
                                            </SelectFormControl>
                                        </TableCellBody>
                                        <TableCellBody>
                                            <TextareaAutosize
                                                id={`comentario${question.index}`}
                                                placeholder="Enter your comment"
                                                onChange={(event) => handleCommentChange(question.index, event.target.value)}
                                            />
                                        </TableCellBody>
                                        <TableCellBody style={{ display: "none" }} id={"observacion" + question.index}>
                                            <TextareaAutosize
                                                id={`Observación ${question.index}`}
                                                placeholder="Enter your observation"
                                                onChange={(event) => handleObservacionChange(question.index, event.target.value)}
                                            />
                                        </TableCellBody>

                                        <TableCellBody style={{ display: "none" }} id={"fecha" + question.index}>
                                            <TextField id={`fecha ${question.index}`}
                                                type='date'
                                                placeholder="Ingrese la fecha"
                                                onChange={(event) => handleFechaChange(question.index, event.target.value)}
                                            />
                                        </TableCellBody>

                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainerStyled>


            <Typography style={{ fontWeight: "bold", color: "red" }}>Note: All items with observations must have an action plan, which must be documented within the next three (3) months.</Typography>
            <br></br>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rating</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Action to Follow</TableCell>
                            <TableCell>Control</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {asociados.map((asociado, index) => (
                            <TableRow key={index}>
                                <TableCell>{asociado.tipo}</TableCell>
                                <TableCell>{asociado.puntaje}</TableCell>
                                <TableCell>{asociado.accion}</TableCell>
                                <TableCell>{asociado.control}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6">score: {((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)} %</Typography>
            <Typography variant="h6">score: {
                (((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                    && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "CONDITIONALLY FAVORABLE" : "UNFAVORABLE")
            }</Typography>


            <div>
                <TextField
                    placeholder='RESPONSIBLE FOR EXECUTING THE VISIT'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableVisita}
                    onChange={(e) =>
                        setResponsableVisita(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad}
                />
                <br />
                <Button onClick={() => {
                    sigPad.current.clear()
                    setFirmaFile(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Clear
                </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Get the base64 value of the drawn image
                        setFirmaFile(base64Data)
                        swal("Thank you", "Signature saved successfully", "success");
                    }}>
                    Save
                </Button>
            </div>




            <div>
                <TextField
                    placeholder='RESPONSIBLE FOR RECEIVING THE VISIT'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableRecibir}
                    onChange={(e) =>
                        setResponsableRecibir(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad2}
                />
                <br />
                <Button onClick={() => {
                    sigPad2.current.clear()
                    setFirmaFile2(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Clear
                </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad2.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Get the base64 value of the drawn image
                        setFirmaFile2(base64Data)
                        swal("Thank you", "Signature saved successfully", "success");
                    }}>
                    Save
                </Button>
            </div>
            <Alert severity="warning">You must sign the form and then click the button to send information.</Alert>

            <Button variant="contained" color="primary" onClick={() => {
                const tableData = [];
                // Recorrer las filas de la tabla
                evaluationData.forEach((category, categoryIndex) => {
                    const categoryData = {
                        category: category.category,
                        questions: [],
                    };
                    category.questions.forEach((question, questionIndex) => {
                        // Obtener los valores de la pregunta, evaluación, comentario y observación
                        const questionData = {
                            question: question.question,
                            evaluation: evaluations[question.index] || "",
                            comment: document.getElementById(`comentario${question.index}`).value || "",
                            observation: document.getElementById(`Observación ${question.index}`).value || "",
                            fecha: document.getElementById(`fecha ${question.index}`).value || "",
                        };
                        // Agregar los valores al array de preguntas
                        categoryData.questions.push(questionData);
                    });
                    // Agregar los datos de la categoría al array principal
                    tableData.push(categoryData);

                });


                const camposRequeridos = [
                    { campo: 'fechaDiligenciamiento', valor: fechaDiligenciamiento, mensaje: 'Filing date' },
                    { campo: 'razonSocial', valor: razonSocial, mensaje: 'Company name' },
                    { campo: 'telefono', valor: telefono, mensaje: 'Phone' },
                    { campo: 'direccion', valor: direccion, mensaje: 'Address' },
                    { campo: 'ciudad', valor: ciudad, mensaje: 'City' },
                    { campo: 'vinculosCiTalsa', valor: vinculosCiTalsa, mensaje: 'Time linked with Talsa' },
                    { campo: 'promedio', valor: promedio, mensaje: 'Purchases in the last year' },
                    { campo: 'opcionSeleccionada', valor: opcionSeleccionada, mensaje: 'Supplies' },
                    { campo: 'pais', valor: pais, mensaje: 'Country' },
                ];

                for (let campo of camposRequeridos) {
                    if (!campo.valor || campo.valor.trim() === '') {
                        swal({
                            title: 'The document must be signed!',
                            text: `Campo faltante: ${campo.mensaje}`,
                            icon: 'warning',
                        });
                        setLoading(false)
                        return false;
                    }
                }


                if (firmaFile == null || firmaFile2 == null) {
                    swal("Document must be signed!", "Please validate the information!", "warning");
                    return;
                }

                const jsonData = JSON.stringify(tableData);
                const jsonData1 = JSON.stringify(rows);
                var raw = JSON.stringify({
                    "datos": `${jsonData}`,
                    "Dfechadilencia": `${fechaDiligenciamiento}`,
                    "Drazonsocial": `${razonSocial}`,
                    "DTelefono": `${telefono}`,
                    "DDireccion": `${direccion}`,
                    "DCiudad": `${ciudad}`,
                    "DTiempoTalsa": `${vinculosCiTalsa}`,
                    "Dcomprasultimosano": `${promedio}`,
                    "Dsuministran": `${opcionSeleccionada}`,
                    "DPais": `${pais}`,
                    "Dfirma1": `${firmaFile}`,
                    "Dfirma2": `${firmaFile2}`,
                    "DCalificacionnumero": `${((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)}`,
                    "DCalificacio": `${(((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                        && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")
                        }`,
                    "Dresponsable": `${responsableVisita}`,
                    "Dresponsable2": `${responsableRecibir}`,
                    "datos2": `${jsonData1}`,
                });
                console.log(firmaFile)
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://consumos.talsa.com.co/api/talsa/pdf/v1/pdfvisitaen", requestOptions)
                    .then(response => {
                        if (response.status == 200) {
                            return response.text(); // Return the response text to the next then
                        } else {
                            setLoading(false)
                            swal("Error", "The document was not created successfully", "error");
                            throw new Error('The document was not created successfully');
                        }
                    })
                    .then(responseText => {
                        const responseJson = JSON.parse(responseText);
                        const base64 = responseJson.base64;
                        console.log(base64)
                        TareasProveedor(localStorage.Proveedor, fechaDiligenciamiento, pais, razonSocial, direccion, vinculosCiTalsa, ciudad, opcionSeleccionada,
                            jsonData, localStorage.Tproveedor).then((result) => {
                                ingresoDOCXWorkmanagerFlujo(base64, result.workflowId, "visita").then(() => {
                                    tableData.forEach(element => {
                                        element.questions.forEach(elementv2 => {
                                            if (elementv2.observation != "") {
                                                TareasProveedorObseracion(localStorage.Proveedor, elementv2.comment, elementv2.observation, result.BarCode, elementv2.question, elementv2.fecha).then((v1) => {
                                                })
                                            }
                                        });
                                    });
                                    swal("Exitoso", "The document was created successfully", "success");
                                });
                            })
                    }).then(result => console.log(result))
                    .catch(error => console.log('error', error));


            }} >
                Send information.
            </Button>
        </EvaluationContainer >
    );
}
