import React, { useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Autocomplete } from '@mui/material';
import { TraerProveedores, Alert, getIDWorkmanager } from '../Componentes/FunctionLogin';

export default function CambioProveedor() {
    const [nit, setNit] = useState('');
    const [loading, setLoading] = useState(false);
    const [vectorT, setVector] = useState([]);
    const [inputValue, setInputValue] = useState(''); // Mantener el valor del campo de entrada
    const timeoutRef = useRef(null); // Usar ref para almacenar el timeout

    const handleEncryptClick = () => {
        if (!nit) {
            // Evitar que se procese si nit está vacío
            return;
        }

        setLoading(true);
        Alert(nit).then((result) => {
            if (result.length > 0) {
                localStorage.setItem("Proveedor", result[0].Proveedor);
                localStorage.setItem("Tproveedor", result[0].Tproveedor);
                localStorage.setItem("Nit", result[0].Nit);
                localStorage.setItem("TipoProveedor", result[0].tipo);
                localStorage.setItem("Pais", result[0].Pais)
                localStorage.setItem("Ciudad", result[0].Ciudad)
                localStorage.setItem("Telefono", result[0].Telefono)
                getIDWorkmanager(result[0].Proveedor).then((resultv2) => {
                    localStorage.setItem("Categoria", resultv2[8].Value);
                    localStorage.setItem("Criticidad", resultv2[9].Value);
                    localStorage.setItem("Email", resultv2[5].Value)
                    localStorage.setItem("Direcc", resultv2[3].Value)
                    resultv2.forEach(element => {
                        if (element.Field === "Radicado") {
                            localStorage.setItem("Radicado", element.Value);
                        }
                    });
                    window.location.reload();
                });
            }
            setLoading(false);
        });
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // Limpiar el timeout anterior
        }

        timeoutRef.current = setTimeout(() => {
            if (newInputValue.length >= 3) {
                TraerProveedores(newInputValue).then((result) => {
                    setVector(result);
                    const nitValue = newInputValue.substring(0, newInputValue.indexOf('-'));
                    setNit(nitValue || ''); // Asegurar que nit no sea undefined
                });
            }
        }, 1000);
    };

    return (
        <Container maxWidth="sm" style={{ padding: '50px' }}>
            <Card sx={{ maxWidth: 600 }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Consultar proveedor
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '650px' }}>
                        <Autocomplete
                            disablePortal
                            style={{ left: '100px', width: '100%' }}
                            onInputChange={handleInputChange}
                            id="controllable-states-demo"
                            options={vectorT.filter((item) => !item.Radicado)}
                            renderInput={(params) => (
                                <TextField
                                    autoComplete="false"
                                    {...params}
                                    label="Proveedor"
                                />
                            )}
                        />
                        <Button
                            variant="contained"
                            onClick={handleEncryptClick}
                            disabled={loading || !nit} // Deshabilitar si loading o nit está vacío
                        >
                            {loading ? <CircularProgress size={24} /> : 'Cambiar'}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
}
